import { useEffect, useState } from 'react'
import {Button, Space, Avatar, Drawer} from 'antd'
import { Link, useLocation } from 'react-router-dom'
import { NavLink } from 'react-router-dom'
// import { useSelector} from 'react-redux'
// import {logoutUser } from '../../shared'
import './index.css'

const SideMenu = ({visible, onClose}) => {
    let location = useLocation()
    // const {data}= useSelector(state => state?.userProfile)
    const data= {}
    const [token, setToken]= useState(null)
    const [currentTab, setCurrentTab]= useState(null)

    useEffect(()=>{
        setCurrentTab(location?.pathname?.split("/")[1])
    }, [location])
    useEffect(()=>{
        setToken(localStorage.getItem('token'))
    },[data])

    const logout= async () => {
        // const result= await logoutUser()
        if(true)
        {
            localStorage.clear()
            window.location.href='/'
        }
    }
  return (
    <Drawer
        title='Menu.'   
        placement="right"  
        visible={visible} 
        onClose={onClose}
        width={340}
        className='sidebar-drawer'
    >
        <div 
            className='flex-col-space-between py-3 px-2'
            >
            <Space 
                direction='vertical' 
                className='width-100'
                >
                <NavLink 
                    to='/about-us' 
                    onClick= {onClose}
                    >
                    <Button 
                        type={currentTab==='about-us' || (token  && currentTab==='') ? 'primary':'default'}
                        className={currentTab==='about-us' || (token  && currentTab==='') ? '': 'tab-button'}
                        block
                        size='large'
                        >
                        About
                    </Button>
                </NavLink>    
                <NavLink 
                    to='/projects' 
                    onClick= {onClose}
                    >
                    <Button 
                        type={currentTab==='projects'?'primary':'default'}
                        className={currentTab!=='projects'?'tab-button':''}
                        size='large'
                        block
                        >
                        Project
                    </Button>
                </NavLink>    
                <NavLink 
                    to='/course-work' 
                    onClick= {onClose}
                    >
                    <Button 
                        type={currentTab==='course-work'?'primary':'default'}
                        className={currentTab!=='course-work'?'tab-button':''}
                        size='large'
                        block
                        >
                        Course-work
                    </Button>
                </NavLink>     
            </Space>
            <Space 
                direction='vertical'
                className='width-100'
                size={15}
            >
                {
                    token &&
                    <div className='center'>
                        <Avatar 
                            src={data?.profile || '/assets/img/signup-img.png'}
                            alt='nf-sounds'
                            shape='square'
                            size={120}
                            />
                    </div>
                }
                <Space 
                    className='width-100' 
                    direction='vertical'
                    > 
                    <Link to={token ? '/user-profile' : '/signup'}>
                        <Button 
                            type={currentTab==='user-profile' || currentTab==='signup' ? 'primary':'default'}
                            onClick= {onClose}
                            size='large'
                            block
                            >
                                {
                                    data?'PROFILE':'SIGN UP'
                                }
                        </Button>
                    </Link>
                    {
                        token ?
                        <Button
                            onClick= {logout}
                            type='primary'
                            size='large'
                            block
                            danger
                            >
                            Logout
                        </Button>: 
                        <Link to={token ? '/user-profile' : '/signup'}>
                            <Button 
                                type={currentTab==='' ? 'primary':'default'}
                                onClick= {onClose}
                                size='large'
                                block
                                >
                                    Log In
                            </Button>
                        </Link>
                    }
                </Space>
            </Space>
        </div>
    </Drawer>
  )
}

export {SideMenu}