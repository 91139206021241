import React, { useState, useEffect, useRef, useCallback } from "react";
import { message } from "antd";
import {
  Button,
  Card,
  Col,
  Flex,
  Image,
  Row,
  Tooltip,
  Typography,
  Form,
} from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import { AddLabelModal } from "../../AddLabelModal";
import {
  CameraOutlined,
  DeleteOutlined,
  EditOutlined,
  FileImageOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import { AlertModal } from "../../AlertModal";
import { useSelector, useDispatch } from "react-redux";
import { actionsApi, checkAuthorization, domainUrl } from "../../../shared";
import { useParams } from "react-router-dom";
import Webcam from "react-webcam";
const CollectSample = () => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { addsample, loadingAddSample } = useSelector(
    (state) => state?.addsample
  );
  const [visiblemodal, setVisibleModal] = useState(false);
  const [confirmmodal, setConfrimModal] = useState(false);
  useEffect(() => {
    dispatch(actionsApi?.getAddSample(id));
  }, [loading]);
  const call = (pageNo = 1, pageSize = 10) => {
    dispatch(actionsApi?.getAddSample(id));
  };
  //upload images
  const handleUploadedImages = (e, index) => {
    const files = Array.from(e.target.files);
    const { userToken } = checkAuthorization();
    files.forEach((file) => {
      const formData = new FormData();
      formData.append("images[]", file);
      const myHeaders = new Headers();
      myHeaders.append("Authorization", userToken);
      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: formData,
      };
      setLoading(true);
      fetch(domainUrl + `/labels/${index}/image`, requestOptions)
        .then((response) => {
          if (response?.status == 201) {
            message.success(response?.statusText);
            setLoading(false);
          } else {
            message.error(response?.statusText);
          }
        })
        .catch((error) => {
          message.error(error.message || "Something went wrong");
        });
    });
  };
  //draw
  const [openSkitch, setOpenSkitch] = useState(false);
  // const canvasRef = useRef(null);
  // const [canvas, setCanvas] = useState(null);
  // const [isDrawing, setIsDrawing] = useState(true);

  // useEffect(() => {
  //   const canvasInstance = new fabric.Canvas(canvasRef.current, {
  //     height: 400,
  //     width: 600,
  //     backgroundColor: 'white',
  //   });
  //   setCanvas(canvasInstance);

  //   return () => {
  //     canvasInstance.dispose();
  //   };
  // }, []);

  // const toggleDrawing = () => {
  //   setIsDrawing(!isDrawing);
  //   if (isDrawing) {
  //     canvas.isDrawingMode = true;
  //   } else {
  //     canvas.isDrawingMode = false;
  //   }
  // };

  // const eraseCanvas = () => {
  //   canvas.clear();
  //   canvas.setBackgroundColor('white', canvas.renderAll.bind(canvas));
  // };

  // const resetCanvas = () => {
  //   canvas.getObjects().forEach(obj => {
  //     canvas.remove(obj);
  //   });
  // };

  //take camera screenshot
  const [openCamera, setOpenCamera] = useState(false);
  const [cameraImages, setCameraImages] = useState({});
  const [openCameraIndex, setOpenCameraIndex] = useState(null);
  const webcamRef = useRef(null);
  const handleOpenCamera = (index) => {
    setOpenCameraIndex(index);
    setOpenCamera(true);
  };
  const capture = useCallback(async () => {
    const imageSrc = webcamRef.current.getScreenshot();
    const blob = await fetch(imageSrc).then((res) => res.blob());
    let formData = new FormData();
    formData.append("images[]", blob, "image.jpg");
    setOpenCamera(false);
    // Send Images To Api
    const { userToken } = checkAuthorization();
    var myHeaders = new Headers();
    myHeaders.append("Authorization", userToken);
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formData,
      redirect: "follow",
    };
    setLoading(true);
    return fetch(domainUrl + `/labels/${openCameraIndex}/image`, requestOptions)
      .then((response) => {
        if (response?.status == 201) {
          message.success(response?.statusText);
          setLoading(false);
          return 1;
        } else {
          message.error(response?.statusText);
          return 0;
        }
      })
      .catch((error) => {
        message.error(error.message || "Something went wrong");
        return 0;
      });
  }, [openCameraIndex]);

  // make train
  const [showTrain, setShowTrain] = useState(false);
  const trainModel = () => {
    const { userToken } = checkAuthorization();
    var myHeaders = new Headers();
    myHeaders.append("Authorization", userToken);
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      redirect: "follow",
    };
    return fetch(domainUrl + `/project/${id}/train`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result?.success) {
          message.success(result?.message);
          message.success(result?.response?.message);
          setShowTrain(true);
          return 1;
        } else {
          message.error(result?.message);
          return 0;
        }
      })
      .catch((error) => {
        message.error(error.message || "Something went wrong");
        return 0;
      });
  };
  // Start Predict Model
  const [predictImage, setPredictImage] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const handlePredictImage = (e) => {
    setSelectedImage(e.target.files[0]);
    const file = e.target.files[0];
    if (file) {
      let predictImage = URL.createObjectURL(file);
      setPredictImage(predictImage);
    } else {
      message.error("No file selected");
    }
  };
  //take predictal webCam
  const [predictalData, setPredictalData] = useState(null);
  let predictWebcamRef = useRef(null);
  const [openPredictalWebcam, setOpenPredictalWebcam] = useState(false);
  const handleOpenPredictalWebcam = () => {
    setOpenPredictalWebcam(true);
  };
  const perdictalCapture = useCallback(async () => {
    const imageSrc = predictWebcamRef.current.getScreenshot();
    setSelectedImage(imageSrc);
    setPredictImage(imageSrc);
    setOpenPredictalWebcam(false);
  }, []);
  const predictModel = async () => {
    let image;
    if (selectedImage.length > 300) {
       image = await fetch(selectedImage).then((res) => res.blob());
    }else{
      image =selectedImage
    }
    let formData = new FormData();
    formData.append("image", image, "image.jpg");
    const { userToken } = checkAuthorization();
    var myHeaders = new Headers();
    myHeaders.append("Authorization", userToken);
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formData,
      redirect: "follow",
    };
    return fetch(domainUrl + `/project/${id}/predict`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result?.success) {
          message.success(result?.message);
          setPredictalData(result?.data);
          return 1;
        } else {
          message.error(result?.message);
          return 0;
        }
      })
      .catch((error) => {
        message.error(error.message || "Something went wrong");
        return 0;
      });
  };
  console.log("predictalData", predictalData);

  return (
    <Flex justify="center" className="mar-10 py-3">
      <div className="width-93">
        <Row gutter={[16, 24]} justify="center">
          <Col span={24}>
            <Typography.Title
              className="primary-heading m-0 mb-3 text-center"
              level={1}
            >
              Collect Samples.
            </Typography.Title>
          </Col>
          <Col span={24}>
            <Flex gap="large" align="center" justify="space-between">
              <Button onClick={() => navigate(-1)} className="sm-btn">
                Back
              </Button>
              <Button
                onClick={() => setVisibleModal(true)}
                className="sm-btn brand-bg text-white"
              >
                Add Label
              </Button>
            </Flex>
          </Col>
        </Row>
        <Row gutter={[16, 24]} style={{ marginTop: "20px" }}>
          {loadingAddSample ? (
            <div className="loadercs">
              <SyncOutlined
                spin
                style={{ color: "var(--main-color)", fontSize: "24px" }}
              />
            </div>
          ) : (
            addsample?.map((data, index) => (
              <Col xs={24} sm={12} md={8} key={index}>
                <Card
                  className="secondary-bg mb-3"
                  style={{
                    position: "relative",
                    height: "600px",
                    paddingBottom: "60px",
                    overflow: "hidden",
                  }}
                >
                  <Typography.Title level={2} className="primary-heading m-0">
                    {data?.name}
                  </Typography.Title>

                  <Tooltip title="Delete">
                    <Button
                      danger
                      onClick={() =>
                        setConfrimModal({ id: data?.id, name: data?.name })
                      }
                      className="cs-delete"
                      shape="circle"
                      icon={<DeleteOutlined color="danger" />}
                    />
                  </Tooltip>

                  <div
                    className="choose-files"
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      gap: "20px",
                      height: "450px",
                      overflow: "auto",
                    }}
                  >
                    {data?.images &&
                      data?.images.length > 0 &&
                      data?.images.map((image, index) => (
                        <img
                          key={index}
                          src={"https://api.mlscholar.com/" + image.image_path}
                          alt={`Uploaded file ${index}`}
                          width="150"
                          height="150"
                        />
                      ))}
                  </div>

                  <div
                    className="card-footer"
                    style={{
                      position: "absolute",
                      bottom: "0",
                      left: "0",
                      right: "0",
                      display: "flex",
                      flexDirection: "row-reverse",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: "20px",
                    }}
                  >
                    <div
                      className="file"
                      style={{
                        width: "fit-content",
                        padding: "4px 13px",
                        borderRadius: "4px",
                        backgroundColor: "#fff",
                      }}
                    >
                      <label
                        htmlFor={`file${index}`}
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          gap: "5px",
                          cursor: "pointer",
                        }}
                      >
                        <p>Images</p>
                        <FileImageOutlined />
                      </label>
                      <input
                        type="file"
                        id={`file${index}`}
                        multiple
                        accept="image/*"
                        style={{ display: "none" }}
                        onChange={(e) => handleUploadedImages(e, data?.id)}
                      />
                    </div>
                    <div
                      className="file"
                      style={{
                        width: "fit-content",
                        padding: "4px 13px",
                        borderRadius: "4px",
                        backgroundColor: "#fff",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          gap: "5px",
                          cursor: "pointer",
                        }}
                        onClick={() => setOpenSkitch(true)}
                      >
                        <p>Draw</p>
                        <EditOutlined />
                      </div>
                    </div>
                    <div
                      className="file"
                      style={{
                        width: "fit-content",
                        padding: "4px 13px",
                        borderRadius: "4px",
                        backgroundColor: "#fff",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          gap: "5px",
                          cursor: "pointer",
                        }}
                        onClick={() => handleOpenCamera(data?.id)}
                      >
                        <p>Webcam</p>
                        <CameraOutlined />
                      </div>
                    </div>
                  </div>
                </Card>
              </Col>
            ))
          )}
        </Row>
        {addsample && addsample.length > 1 && (
          <Flex align="center" justify="center">
            <Button
              onClick={() => trainModel()}
              className="sm-btn brand-bg text-white"
            >
              Train Model
            </Button>
          </Flex>
        )}
        {/* ######################################## */}
        {showTrain && (
          <div
            className="train"
            style={{
              backgroundColor: "var(--secondary-color)",
              padding: "30px 0px",
              margin: "20px 0px",
              textAlign: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "20px",
                marginBottom: "20px",
              }}
            >
              <div
                className="file"
                style={{
                  width: "fit-content",
                  padding: "4px 13px",
                  borderRadius: "4px",
                  backgroundColor: "#fff",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "5px",
                  }}
                  onClick={() => handleOpenPredictalWebcam()}
                >
                  <p>Test with</p>
                  <CameraOutlined />
                </div>
              </div>
              <div
                className="file"
                style={{
                  width: "fit-content",
                  padding: "4px 13px",
                  borderRadius: "4px",
                  backgroundColor: "#fff",
                }}
              >
                <label
                  htmlFor={`trainImage`}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "5px",
                  }}
                >
                  <p>Test with</p>
                  <FileImageOutlined />
                </label>
                <input
                  type="file"
                  id="trainImage"
                  multiple
                  accept="image/*"
                  style={{ display: "none" }}
                  onChange={(e) => handlePredictImage(e)}
                />
              </div>
            </div>
            {predictImage && <img src={predictImage} alt="" />}
            {predictImage && (
              <Flex
                align="center"
                justify="center"
                style={{ marginTop: "20px" }}
              >
                <Button
                  onClick={() => predictModel()}
                  className="sm-btn brand-bg text-white"
                >
                  Predict Model
                </Button>
              </Flex>
            )}
            {predictalData && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "20px",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: "15px",
                }}
              >
                <div
                  style={{
                    backgroundColor: "#fff",
                    minWidth: "200px",
                    borderRadius: "10px",
                    position: "relative",
                    overflow: "hidden",
                    height: "20px",
                  }}
                >
                  <div
                    style={{
                      transition: "right .4s ease-in-out .3s",
                      backgroundColor: "var(--brand-color)",
                      position: "absolute",
                      top: "0",
                      left: "0",
                      bottom: "0",
                      right: `${
                        100 - Math.round(predictalData?.confidence * 100)
                      }%`,
                    }}
                  ></div>
                  <span
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      right: 0,
                      bottom: 0,
                      zIndex: 333,
                    }}
                  >
                    {Math.round(predictalData?.confidence * 100)}%
                  </span>
                </div>
                <div>
                  <span
                    style={{
                      fontSize: "16px",
                      marginRight: "5px",
                      fontWeight: "700",
                    }}
                  >
                    Label:
                  </span>
                  <span>{predictalData?.label}</span>
                </div>
              </div>
            )}
          </div>
        )}

        {/* ################## */}
      </div>
      <AddLabelModal
        visiblemodal={visiblemodal}
        getAllSamples={call}
        projectID={id}
        onClose={() => setVisibleModal(false)}
      />
      <AlertModal
        confirmmodal={confirmmodal}
        getAllSamples={call}
        onClose={() => setConfrimModal(false)}
      />
      {/* Skitch layOut */}
      {/* {openSkitch&&
      <div style={{ padding: '20px' }}>
      <h2>Draw a new example</h2>
      <canvas ref={canvasRef} style={{ border: '1px solid black' }} />

      <div style={{ marginTop: '10px' }}>
        <button onClick={toggleDrawing}>
          {isDrawing ? 'Erase' : 'Draw'}
        </button>
        <button onClick={eraseCanvas}>Reset</button>
      </div>
    </div>} */}
      {/* Camera layOut */}

      {openCamera && (
        <div
          className="camera-layout"
          style={{
            position: "fixed",
            top: "0",
            left: "0",
            bottom: "0",
            right: "0",
            zIndex: "100",
            backgroundColor: "rgba(0,0,0,.2)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              backgroundColor: "#FFF",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Webcam
              screenshotFormat="image/jpeg"
              width={400}
              height={300}
              ref={webcamRef}
            />
            <Flex
              gap="large"
              align="center"
              justify="space-between"
              style={{ padding: "20px 0px" }}
            >
              <Button onClick={capture} className="sm-btn brand-bg text-white">
                Add
              </Button>
              <Button onClick={() => setOpenCamera(false)} className="sm-btn">
                Cancel
              </Button>
            </Flex>
          </div>
        </div>
      )}
      {/* Predict webcam */}
      {openPredictalWebcam && (
        <div
          className="camera-layout"
          style={{
            position: "fixed",
            top: "0",
            left: "0",
            bottom: "0",
            right: "0",
            zIndex: "100",
            backgroundColor: "rgba(0,0,0,.2)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              backgroundColor: "#FFF",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Webcam
              screenshotFormat="image/jpeg"
              width={400}
              height={300}
              ref={predictWebcamRef}
            />
            <Flex
              gap="large"
              align="center"
              justify="space-between"
              style={{ padding: "20px 0px" }}
            >
              <Button
                onClick={perdictalCapture}
                className="sm-btn brand-bg text-white"
              >
                Add
              </Button>
              <Button
                onClick={() => setOpenPredictalWebcam(false)}
                className="sm-btn"
              >
                Cancel
              </Button>
            </Flex>
          </div>
        </div>
      )}
    </Flex>
  );
};

export { CollectSample };
