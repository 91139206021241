import { Button, Card, Col, Flex, Row, Typography } from 'antd'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

const CollectTrainComponent = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { pagination } = useSelector(state => state?.projects)

    const data = [
        {
            title: "Collect Samples.",
            desc: "Start by gathering examples of the image you want the system to recognize.",
            btntext: "Collect",
            path: `/collectsample/${pagination?.projectIds?.[0] || ''}`
        },
        {
            title: "Train and Test.",
            desc: "Use the examples to teach your machine learning how to identify these images and verify how well your model performs.",
            btntext: "Train",
            path: "/train"
        },
    ]

    return (
        <Flex justify='center' className='mar-10 py-3' align='center'>
            <div className='width-93'>
                <Row gutter={[16, 16]} justify='center'>
                    {
                        data?.map((dat, d) =>
                            <Col xs={24} sm={24} md={12} lg={10} key={d}>
                                <Card className='card-login'>
                                    <Typography.Title level={2} className='secondary-heading text-center m-0 mb-3'>
                                        {dat?.title}
                                    </Typography.Title>
                                    <Typography.Text className='body-text text-justify mb-3'>
                                        {dat?.desc}
                                    </Typography.Text>
                                    <Button onClick={() => navigate(dat?.path)} className='sm-btn brand-bg text-white'>
                                        {dat?.btntext}
                                    </Button>
                                </Card>
                            </Col>
                        )
                    }
                </Row>
            </div>
        </Flex>
    )
}

export { CollectTrainComponent }
