import React, { useState } from 'react'
import { Button, Col, Space, Image, Row, Typography } from 'antd'
import { LoginRegisterIntroModal } from '../Structure'

const WelcomeSection = () => {

    const [open, setOpen]= useState(false)

    return (
      <>
        <div className='center'>
          <div className='width-93 my-5'>
            <Row gutter={[32, 32]} align={'middle'}>
              <Col xs={24} sm={24} md={12}>
                <div className='center'>
                  <Image 
                    src='/assets/robot.png' 
                    preview={false} 
                    width='70%'
                  />
                </div>
              </Col>
              <Col xs={24} sm={24} md={12}>
                  <Space direction='vertical' size={12}>
                    <Typography.Title className='my-0' level={3}>
                      Welcome to MLScholar!
                    </Typography.Title>
                    <Typography.Title className='my-0' level={1}>
                      Get Started with Machine Learning
                    </Typography.Title>
                    <Typography.Title className='my-0' level={4}>
                      Ready to dive into the world of machine learning? Start your journey now!
                    </Typography.Title>
                    <Button onClick={()=> setOpen(true)} className='larg-btn brand-bg text-white'>
                      Log In
                    </Button>
                  </Space>
              </Col>
            </Row>
          </div>
        </div>
        <LoginRegisterIntroModal open={open} onClose={()=> setOpen(false)}/>
      </>
    )
}

export {WelcomeSection}